import React from "react";
import locales from "../constants";
import { Layout, ContactFormDark } from "../components";
import { Link } from "gatsby";
import $ from "jquery";

const AudytSocialMediaPage = ({ location: { pathname } }) => {
  const freeAuditBtnAction = (e) => {
    e.preventDefault();
    $('#audytType option[value="Darmowy audyt"]').attr("selected", "selected");
    $("#audytType").css("color", "black");
  };
  const advancedAuditBtnAction = (e) => {
    e.preventDefault();
    $('#audytType option[value="Zaawansowany audyt"]').attr(
      "selected",
      "selected"
    );
    $("#audytType").css("color", "black");
  };

  if (typeof window !== `undefined`) {
    $(window).scroll(function () {
      if ($("#inViewport, #inViewport2, #inViewport3").isOnScreen()) {
        // The element is visible, do something
        setTimeout(function () {
          $("#inViewport, #inViewport2, #inViewport3").addClass("active");
        }, 500);
      } else {
        $("#inViewport, #inViewport2, #inViewport3").removeClass("active");
      }
    });

    $.fn.isOnScreen = function () {
      var win = $(window);

      var viewport = {
        top: win.scrollTop(),
        left: win.scrollLeft(),
      };
      viewport.right = viewport.left + win.width();
      viewport.bottom = viewport.top + win.height();

      var bounds = this.offset();
      bounds.right = bounds.left + this.outerWidth();
      bounds.bottom = bounds.top + this.outerHeight();

      return !(
        viewport.right < bounds.left ||
        viewport.left > bounds.right ||
        viewport.bottom < bounds.top ||
        viewport.top > bounds.bottom
      );
    };
  }
  return (
    <Layout
      seo={{
        title: "Audyt social media",
        description:
          "Przygotujemy dla Ciebie audyt Twoich kanałów social media. Audyt social media pozwoli Ci na dogłebną analizę dotyczasowych działań w ramach prowadzenia strony na Facebooku czy też Instagramie. Skorzystaj z naszego  8 letniego doświadczenia i rozwiń swoje social media. ",
        href: pathname,
        lang: "pl",
      }}
    >
      <span className="anchor" id="home"></span>
      <div className="subpage-header subpage-header--single-offer">
        <h1 className="subpage-header__title">
          Audyt kanałów <br /> <span>social media</span>
        </h1>
      </div>

      <div className="single-offer-menu-container">
        <div className="row">
          <div className="col-lg-3">
            <Link to="/uslugi/#social-media">
              <div className="back-wrapper">
                <span className="back-wrapper__arrow"></span>
                <p className="back-wrapper__title">Usługi</p>
              </div>
            </Link>
            <p className="nav-title">Social Media:</p>
            <ul>
              <li className="current">
                <a href="#home">Audyt kanałów social media</a>
              </li>
              <li>
                <Link to="/strategia-komunikacji-social-media/">
                  Strategia komunikacji <br /> social media
                </Link>
              </li>
              <li>
                <Link to="/prowadzenie-strony-na-facebooku/">
                  Prowadzenie strony na Facebooku
                </Link>
              </li>
              <li>
                <Link to="/prowadzenie-konta-instagram/">
                  Prowadzenie konta Instagram
                </Link>
              </li>
              <li>
                <Link to="/prowadzenie-strony-na-linkedin/">
                  Prowadzenie strony na LinkedIn
                </Link>
              </li>
              <li>
                <Link to="/kampanie-facebook-ads/">Kampanie Facebook Ads</Link>
              </li>
              <li>
                <Link to="/kampanie-na-instagramie/">
                  Kampanie na Instagramie
                </Link>
              </li>
              <li>
                <Link to="/tiktok-ads/">Kampanie TikTok Ads</Link>
              </li>
              {/* <li>
                <Link to="/chatbot-w-komunikacji-marki/">
                  Chatbot w komunikacji marki
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
      </div>

      <section className="single-offer-section-text-left single-offer-section-text-left--cat-3">
        <div className="row">
          <div className="col-xl-8 offset-xl-4 col-lg-9 offset-lg-3">
            <div className="row align-items-center">
              <div className="col-lg-6 text-col">
                <h2 className="text-col__title mb-40">
                  Nowa <br /> perspektywa
                </h2>
                <p className="text-col__desc">
                  Zastanawiasz się, czy kanały social media Twojej firmy są
                  dobrze prowadzone? Chciałbyś wskazać ich słabe i mocne obszary
                  i ocenić potencjał zwiększenia efektywności prowadzonych
                  działań? Spójrz na swoje kanały z nowej perspektywy - z punktu
                  widzenia agencji, która dzięki doświadczeniu wielu
                  specjalistów każdego dnia pomaga w rozwoju firm z dziesiątek
                  branż.
                </p>
              </div>
              <div className="col-lg-6 img-col">
                <img
                  className="img-fluid img-col__image"
                  src={require("../assets/images/audyt-fb-google-img-1.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-text-lines">
        <div className="row">
          <div className="col-xl-8 offset-lg-3 text-col">
            <h2 className="text-col__title title title--1" id="inViewport">
              Zweryfikuj ewentualne błędy
            </h2>
            <h2 className="text-col__title title title--2" id="inViewport2">
              Wykorzystaj najnowsze rozwiązania
            </h2>
            <h2 className="text-col__title title title--3" id="inViewport3">
              Sprawdź nowe możliwości
            </h2>
          </div>
        </div>
      </section>
      <section className="single-offer-section-big-img single-offer-section-big-img--audyt-sm">
        <div className="row">
          <div className="col-xl-7 col-lg-8 offset-lg-4">
            <h2 className="single-offer-section-big-img__title">
              Dokładna Analiza
            </h2>
            <div className="image-container">
              <img
                className="single-offer-section-big-img__image image image--desktop"
                src={require("../assets/images/audyt-sm-graph.jpg")}
                alt=""
              />
              <img
                className="single-offer-section-big-img__image image image--mobile"
                src={require("../assets/images/audyt-sm-graph-mobile.jpg")}
                alt=""
              />
              <p className="image-container__text text text--1">
                Analiza publikowanych <br /> treści (grafiki oraz copy)
              </p>
              <p className="image-container__text text text--2">
                Wykaz ewentualnych błędów lub brakujących elementów w
                analizowanym kanale
              </p>
              <p className="image-container__text text text--3">
                Analiza fanów/ <br />
                obserwarotów
              </p>
              <p className="image-container__text text text--4">
                Analiza dotychczasowej <br />
                komunikacji
              </p>
              <p className="image-container__text text text--5">
                Wskazówki oraz informacje o dobrych praktykach, które pozwolą na
                zmaksymalizowanie efektywności prowadzonych działań
              </p>
              <ul className="big-bullets list-mobile">
                <li>Analiza publikowanych treści (grafiki oraz copy)</li>
                <li>
                  Wykaz ewentualnych błędów lub brakujących elementów w
                  analizowanym kanale{" "}
                </li>
                <li>Analiza fanów/ obserwatorów</li>
                <li>Analiza dotychczasowej komunikacji</li>
                <li>
                  Wskazówki oraz informacje o dobrych praktykach, które pozwolą
                  na zmaksymalizowanie efektywności prowadzonych działań
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-table">
        <div className="row">
          <div className="col-xl-7 col-lg-8 offset-lg-4">
            <h2 className="single-offer-section-table__title">
              Wybierz rozwiązanie dla siebie
            </h2>
            <div className="table-container">
              <div className="custom-row">
                <div className="small-col">
                  <div className="table-container">
                    <h2 className="table-container__title title title--turqoise">
                      Bezpłatny audyt social media
                    </h2>
                    <p className="table-container__text">
                      Chcesz dowiedzieć się w jakiej kondycji są Twoje social
                      media i wskazać ich główne obszary do poprawy? Skorzystaj
                      z naszego bezpłatnego audytu kondycji social media.
                    </p>
                    <ul className="big-bullets">
                      <li>Jeden wybrany kanał social media </li>
                      <li>
                        Podstawowa analiza komunikacji i publikowanych treści
                      </li>
                      <li>
                        Wykaz ewentualnych błędów lub brakujących
                        elementów/informacji w analizowanym kanale
                      </li>
                    </ul>
                    <a
                      href="#kontakt"
                      onClick={freeAuditBtnAction}
                      className="btn-box btn"
                    >
                      <strong>skontaktuj się z nami</strong>
                    </a>
                  </div>
                </div>
                <div className="big-col">
                  <div className="table-container">
                    <h2 className="table-container__title title title--violet">
                      Zaawansowany audyt <br /> social media
                    </h2>
                    <p className="table-container__text">
                      Rozwiń social media swojej firmy na wyżyny możliwości!
                    </p>
                    <ul className="big-bullets">
                      <li>Dowolna liczba kanałów social media</li>
                      <li>
                        Kompleksowa, rozbudowana analiza komunikacji i
                        publikowanych materiałów
                      </li>
                      <li>Analiza fanów / obserwatorów</li>
                      <li>
                        Wykaz ewentualnych błędów lub brakujących elementów w
                        analizowanym kanale
                      </li>
                      <li>
                        Rekomendacje w stosunku do najnowszych trendów i
                        rozwiązań w obszarze social media
                      </li>
                      <li>
                        Wskazówki oraz informacje o dobrych praktykach, które
                        pozwolą na maksymalizację efektywności prowadzonych
                        kanałów
                      </li>
                      <li>
                        Zaawansowana analiza kampanii Facebook Ads Konsultacje
                        ze Specjalistą Social Media i Facebook Ads
                      </li>
                    </ul>
                    <a
                      href="#kontakt"
                      onClick={advancedAuditBtnAction}
                      className="btn-box btn"
                    >
                      <strong>zapytaj o wycenę</strong>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <span className="anchor" id="kontakt"></span>
      <section className="single-offer-section-contact">
        <div className="form-wrapper">
          <h2 className="single-offer-section-contact__title mb-40">
            Skontaktuj się z nami! <br />
            Wypełnij <span>formularz</span>
          </h2>
          <ContactFormDark locales={locales["pl"]} select={true} />
        </div>
        <img
          className="img-fluid single-offer-section-contact__hand"
          src={require("../assets/images/home-contact-hand-right.png")}
          alt=""
        />
      </section>
    </Layout>
  );
};
export default AudytSocialMediaPage;
